import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";

export const startTourDespesa_Desk = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: "shepherd-theme-default",
      scrollTo: { behavior: "smooth", block: "center" },
    },
    useModalOverlay: true,
  });

  // Passo 1
  tour.addStep({
    id: "custom-step",
    text: `
        <div class="custom-step-text" style="text-align: center;">
            <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
            <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua despesa e adicionar seus gastos e Km rodados..</p>
            <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
        </div>`,
    attachTo: {
      element: ".my-element",
      on: "right",
    },
    classes: "custom-tooltip-class",
    buttons: [
      {
        text: "Iniciar Tutorial",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
    cancelIcon: {
      enabled: false,
    },
    scrollTo: { behavior: "smooth", block: "center" },
    highlightClass: "highlighted-element",
  });

  // Passo 2
  tour.addStep({
    id: "second-step",
    text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Envie seu comprovante</h4>
                <p class="custom-step-text-body1">Ao clicar aqui você pode adicionar a foto do comprovante de seus gastos.</p>
            </div>
        `,
    attachTo: {
      element: ".comprovante",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Justifique seu gasto</h4>
                <p class="custom-step-text-body1">Adicione sua justificativa/descrição para o motivo do seu gasto com a despesa</p>
            </div>
        `,
    attachTo: {
      element: ".justificativa",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Categorias</h4>
                <p class="custom-step-text-body1">Aqui você seleciona com oque foi gasto, alimentação, transporte, estadia entre outras.</p>
            </div>
        `,
    attachTo: {
      element: ".categoria",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Total gasto</h4>
                <p class="custom-step-text-body1">Adicione o total que você gastou nessa despesa.</p>
            </div>
        `,
    attachTo: {
      element: ".totalgasto",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Data de compra</h4>
                <p class="custom-step-text-body1">Adicione a data que você realizou a compra</p>
            </div>
        `,
    attachTo: {
      element: ".datadecompra",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  // Iniciar o tour
  tour.start();
};

export const startTourDespesa_Mob = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: "shepherd-theme-default",
      scrollTo: { behavior: "smooth", block: "center" },
    },
    useModalOverlay: true,
  });

  // Passo 1
  tour.addStep({
    id: "custom-step",
    text: `
          <div class="custom-step-text" style="text-align: center;">
              <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
              <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua despesa e adicionar seus gastos e Km rodados..</p>
              <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
          </div>`,
    attachTo: {
      element: ".my-element",
      on: "right",
    },
    classes: "custom-tooltip-class",
    buttons: [
      {
        text: "Iniciar Tutorial",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
    cancelIcon: {
      enabled: false,
    },
    scrollTo: { behavior: "smooth", block: "center" },
    highlightClass: "highlighted-element",
  });

  // Passo 2
  tour.addStep({
    id: "second-step",
    text: `
              <div class="custom-step-text" style="text-align: left;">
                  <h4 class="custom-step-text-typography1">Envie seu comprovante</h4>
                  <p class="custom-step-text-body1">Ao clicar aqui você pode adicionar a foto do comprovante de seus gastos.</p>
              </div>
          `,
    attachTo: {
      element: ".comprovante",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
              <div class="custom-step-text" style="text-align: left;">
                  <h4 class="custom-step-text-typography1">Justifique seu gasto</h4>
                  <p class="custom-step-text-body1">Adicione sua justificativa/descrição para o motivo do seu gasto com a despesa</p>
              </div>
          `,
    attachTo: {
      element: ".justificativa",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
              <div class="custom-step-text" style="text-align: left;">
                  <h4 class="custom-step-text-typography1">Categorias</h4>
                  <p class="custom-step-text-body1">Aqui você seleciona com oque foi gasto, alimentação, transporte, estadia entre outras.</p>
              </div>
          `,
    attachTo: {
      element: ".categoria",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
              <div class="custom-step-text" style="text-align: left;">
                  <h4 class="custom-step-text-typography1">Total gasto</h4>
                  <p class="custom-step-text-body1">Adicione o total que você gastou nessa despesa.</p>
              </div>
          `,
    attachTo: {
      element: ".totalgasto",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "second-step",
    text: `
              <div class="custom-step-text" style="text-align: left;">
                  <h4 class="custom-step-text-typography1">Data de compra</h4>
                  <p class="custom-step-text-body1">Adicione a data que você realizou a compra</p>
              </div>
          `,
    attachTo: {
      element: ".datadecompra",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  // Iniciar o tour
  tour.start();
};

export const startTourHodometro_Desk = (handleClickNext) => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: "shepherd-theme-default",
      scrollTo: { behavior: "smooth", block: "center" },
    },
    useModalOverlay: true,
  });

  // Passo 1
  tour.addStep({
    id: "custom-step",
    text: `
        <div class="custom-step-text" style="text-align: center;">
            <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
            <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua despesa e adicionar seus gastos e Km rodados..</p>
            <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
        </div>`,
    attachTo: {
      element: ".my-element",
      on: "right",
    },
    classes: "custom-tooltip-class",
    buttons: [
      {
        text: "Iniciar Tutorial",
        action: () => {
          tour.next();
          handleClickNext();
        },

        classes: "custom-next-button",
      },
    ],
    cancelIcon: {
      enabled: false,
    },
    scrollTo: { behavior: "smooth", block: "center" },
    highlightClass: "highlighted-element",
  });

  // Iniciar o tour
  tour.start();
};

export const startTourNovaViagem_Mobile = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      cancelIcon: {
        enabled: true,
      },
      classes: "shepherd-theme-default",
      scrollTo: { behavior: "smooth", block: "center" },
    },
    useModalOverlay: true,
  });

  // Passo 1
  tour.addStep({
    id: "custom-step",
    text: `
        <div class="custom-step-text" style="text-align: center;">
            <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
            <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua despesa e adicionar seus gastos e Km rodados..</p>
            <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
        </div>`,
    attachTo: {
      element: ".my-element",
      on: "right",
    },
    classes: "custom-tooltip-class",
    buttons: [
      {
        text: "Iniciar Tutorial",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
    cancelIcon: {
      enabled: false,
    },
    scrollTo: { behavior: "smooth", block: "center" },
    highlightClass: "highlighted-element",
  });

  tour.addStep({
    id: "second-step",
    text: `
            <div class="custom-step-text" style="text-align: left;">
            <h4 class="custom-step-text-typography1">Insira um título</h4>
            <p class="custom-step-text-body1">Digite um título para a sua despesa, como por exemplo: "Visita ao cliente".</p>
        </div>
        `,
    attachTo: {
      element: ".titulo",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: () => {
          tour.next();
        },
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "fourth-step",
    text: `
    <div class="custom-step-text" style="text-align: left;">
        <h4 class="custom-step-text-typography1">Selecione seu centro de custo</h4>
        <p class="custom-step-text-body1">Escolha o centro de custo para onde a despesa será destinada.</p>
    </div>
    `,
    attachTo: {
      element: ".centrodecusto",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "fifth-step",
    text: `
    <div class="custom-step-text" style="text-align: left;">
        <h4 class="custom-step-text-typography1">Defina as datas da despesa</h4>
        <p class="custom-step-text-body1">Escolha a data de início e a data de término previstas para a sua despesa.</p>
    </div>
    `,
    attachTo: {
      element: ".data",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "sixth-step",
    text: `
    <div class="custom-step-text" style="text-align: left;">
        <h4 class="custom-step-text-typography1">Solicitar adiantamento</h4>
        <p class="custom-step-text-body1">Se você ainda não possui o dinheiro para viagem marque essa opção.</p>
    </div>
    `,
    attachTo: {
      element: ".solicitar",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "sixth-step",
    text: `
    <div class="custom-step-text" style="text-align: left;">
        <h4 class="custom-step-text-typography1">Sem adiantamento</h4>
        <p class="custom-step-text-body1">Se você já possui o dinheiro para viagem marque essa opção.</p>
    </div>
    `,
    attachTo: {
      element: ".semadiantamento",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "sixth-step",
    text: `
    <div class="custom-step-text" style="text-align: left;">
        <h4 class="custom-step-text-typography1">Saldo Livre</h4>
        <p class="custom-step-text-body1">Para quem deseja simplesmente registrar seus gastos e garantir o reembolso de forma prática e rápida.</p>
    </div>
    `,
    attachTo: {
      element: ".saldolivre",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
  });

  tour.addStep({
    id: "sixth-step",
    text: `
    <div class="custom-step-text" style="text-align: left;">
        <h4 class="custom-step-text-typography1">O valor do seu saldo</h4>
        <p class="custom-step-text-body1">O adicione o quanto você tem ou o quanto você precisa.</p>
    </div>
    `,
    attachTo: {
      element: ".totalinicial",
      on: "bottom",
    },
    classes: "custom-tooltip-class",
    canClickTarget: false,
    buttons: [
      {
        text: "Voltar",
        action: tour.back,
        classes: "custom-back-button",
      },
      {
        text: "Próximo",
        action: tour.next,
        classes: "custom-next-button",
      },
    ],
  });

  // Iniciar o tour
  tour.start();
};

export const startTourViagemCriada_Mobile = () => {
    const tour = new Shepherd.Tour({
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            classes: 'shepherd-theme-default',
            scrollTo: { behavior: 'smooth', block: 'center' },
        },
        useModalOverlay: true,
    });

    // Passo 1
    tour.addStep({
        id: 'custom-step',
        text: `
        <div class="custom-step-text" style="text-align: center;">
            <h4 class="custom-step-text-typography1">Olá! Eu sou o guia do sistema e estou aqui para ajudar você.</h4>
            <p class="custom-step-text-body1">Essa é uma experiência simples e ágil. Você poderá criar sua despesa e adicionar seus gastos e Km rodados..</p>
            <p class="custom-step-text-body1" style="margin-top: 15px">Vamos começar?</p>
        </div>`,
        attachTo: {
            element: '.my-element',
            on: 'right'
        },
        classes: 'custom-tooltip-class',
        buttons: [
            {
                text: 'Iniciar Tutorial',
                action: tour.next,
                classes: 'custom-next-button'
            }
        ],
        cancelIcon: {
            enabled: false
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        highlightClass: 'highlighted-element'
    });

    // Passo 2
    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Edite o titulo da sua viagem</h4>
                <p class="custom-step-text-body1">Aqui neste campo, você pode alterar o titulo da sua viagem tranquilamente.</p>
            </div>
        `,
        attachTo: {
            element: '.titulo_mob',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Exclua sua viagem</h4>
                <p class="custom-step-text-body1">Aqui neste botão você pode excluir a sua viagem, caso você tenha criado apenas para ver como funciona.</p>
            </div>
        `,
        attachTo: {
            element: '.excluir_mob',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Adicione sua despesa</h4>
                <p class="custom-step-text-body1">Aqui neste botão você pode adicionar sua despesa.</p>
            </div>
        `,
        attachTo: {
            element: '.adicionardespesa_mob',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Adicione seu hodometro</h4>
                <p class="custom-step-text-body1">Aqui neste botão você pode adicionar seu hodometro, com fotos ou utilizando o celular com o GPS.</p>
            </div>
        `,
        attachTo: {
            element: '.adicionarhodometro_mob',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Saldo Atual e Situação de Adiantamento</h4>
                <p class="custom-step-text-body1">Exibe o saldo disponível e o status do adiantamento para a viagem atual. Caso solicitado, o adiantamento aparecerá em destaque azul; se não solicitado, exibirá "Adiantamento não solicitado.</p>
            </div>
        `,
        attachTo: {
            element: '.total_mob',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });

    tour.addStep({
        id: 'second-step',
        text: `
            <div class="custom-step-text" style="text-align: left;">
                <h4 class="custom-step-text-typography1">Seus limites</h4>
                <p class="custom-step-text-body1">Aqui você pode verificar o quanto você pode gastar com almoço, transporte, estadia etc.</p>
            </div>
        `,
        attachTo: {
            element: '.limites_mob',
            on: 'bottom'
        },
        classes: 'custom-tooltip-class',
        canClickTarget: false,
        buttons: [
            {
                text: 'Voltar',
                action: tour.back,
                classes: 'custom-back-button'
            },
            {
                text: 'Próximo',
                action: () => {
                    tour.next();
                },
                classes: 'custom-next-button'
            }
        ],
    });


    // Iniciar o tour
    tour.start();
};

