import { Grid, InputAdornment, Pagination, TextField, Typography } from "@mui/material"
import { SearchOutlined } from "@mui/icons-material";
import { EnumTipo, hasApprovedAdiantamento } from "../../../../enums/enumPrestacaoDeContas";
import { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
import { IconsSourceSvg } from "../../../../images/Icons";
import ULoaderVooMessage from "../../../../components/uloadervoo/loader";
import { FilterComponent_mob } from "../../../../components/autalii_expanses/FiltersViagens";
import dayjs from "dayjs";
import { CardMyViagem_mob } from "../../../../components/autalii_expanses/card_viagem/index_mob";
import { CardInitialUser_Mob } from "../../../../components/autalii_expanses/card_initialuser";
import { ViagemViewComponent_mob } from "../../../../components/autalii_expanses/viagem/index_mob";
import usePagination from "../../../../components/pagination";

export const MinhasViagensM = () => {
    const [minhasviagens, setMinhasViagens] = useState([]);
    const [criarNovaviagem, setCriarNovaViagem] = useState(false);
    const [openViagem, setOpenViagem] = useState(false);
    const [viagem, setViagem] = useState(null);
    const [typeDialog, setTypeDialog] = useState(0);
    const [openDeleteViagem, setOpenDeleteViagem] = useState(false)
    const [novaviagem, setNovaviagem] = useState({
        titulo: "",
        id_centrocusto: 0,
        adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.solicitado }],
        totalinicial: 0,
        tem_multimonetario: false,
    });
    const [alterViagem, setAlterViagem] = useState({
        titulo: '', // ou outro valor padrão
        // outros campos, se necessário
    })
    const [alterTitulo, setAlterTitulo] = useState(false)
    const [pessoa, setPessoa] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState("")
    const [filterViagens, setFilterViagens] = useState(minhasviagens);
    const [openFilters, setOpenFilters] = useState(false)
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const initialStatuses = Object.keys(EnumTipo.viagemstatus).reduce((acc, key) => {
        acc[key] = false;  // default status is inactive
        return acc;
    }, {});
    const [statuses, setStatuses] = useState(initialStatuses);
    const [temDespesa, setTemDespesa] = useState(false);

    const hasActiveStatus = Object.values(statuses).some((value) => value);

    const itemsPerPage = 6;

    // Usar o hook de paginação
    const { currentItems, page, totalPages, setPage } = usePagination(filterViagens, itemsPerPage);

    // Função para mudar de página
    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { enqueueSnackbar } = useCustomSnackbar();

    const handlesearchname = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredViagens = minhasviagens.filter(viagem =>
            viagem.titulo.toLowerCase().includes(searchTerm)
        );

        setPage(1)

        setFilterViagens(filteredViagens);
    }

    const FiltersViagem = () => {
        const activeStatusValues = Object.keys(statuses)
            .filter((key) => statuses[key])  // Get active statuses
            .map((statusName) => EnumTipo.viagemstatus[statusName])  // Convert to enum numbers
            .filter((value) => value !== undefined);  // Filter out any undefined values

        // If no filters are active, set to all trips; otherwise, filter by active statuses
        const filtered = activeStatusValues.length > 0
            ? minhasviagens.filter((viagem) => activeStatusValues.includes(viagem.status))
            : minhasviagens;

        setFilterViagens(filtered);
    }

    const applyFilters = () => {
        getMinhasViagens()
        setOpenFilters(false)
    }

    const handleCloseViagem = () => {
        setOpenViagem(false);
        setViagem(null);
        setAlterViagem({});
        setAlterTitulo(false)
    };

    const handleChangenameViagem = (e) => {
        let title = e.target.value
        setAlterViagem(prevState => ({
            ...prevState,
            titulo: title
        }));
        setAlterTitulo(true)
    }


    const getMinhasViagens = async () => {
        setLoading(true);
        setLoadingMessage("Buscando suas viagens...");
        try {
            let model = {
                data_inicial: dayjs(new Date(new Date().getFullYear(), 0, 1)).toISOString(), // Start of the year in ISO format
                data_final: dayjs(new Date(new Date().getFullYear(), 11, 31)).toISOString(), // End of the year in ISO format
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: true
            };

            // Override dates if the user selected a date range
            if (selectedDate[0] && selectedDate[1]) {
                model.data_inicial = dayjs(selectedDate[0]).toISOString();
                model.data_final = dayjs(selectedDate[1]).toISOString();
            }

            // model.data_final.setHours(23, 59, 59, 999);

            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);

            setSelectedDate([null, null]);

            const sortedViagens = response.viagens.sort((a, b) => new Date(b.datadecriacao) - new Date(a.datadecriacao));

            setMinhasViagens(sortedViagens);

            setPessoa(response.pessoa);
        } catch (error) {
            enqueueSnackbar('Erro ao obter as suas viagens.', { variant: 'error' });
        } finally {
            setLoading(false);

        }
    };

    const getViagemForID = async () => {
        setLoadingMessage("")
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getViagem(viagem.id);
            setViagem(response)
        } catch (error) {
            enqueueSnackbar('Erro ao obter sua viagem.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }


    const confirmCriarNovaViagem = async () => {
        setLoading(true)
        try {
            if (novaviagem.titulo === "" || novaviagem.titulo === null) {
                enqueueSnackbar('Titulo da viagem não pode ser vazio.', { variant: 'warning' });
                return
            }
            if (novaviagem.id_centrocusto === 0) {
                enqueueSnackbar('Centro de custo não pode ser vazio.', { variant: 'warning' });
                return
            }

            if (novaviagem.datafim === null) {
                enqueueSnackbar('Defina uma data final.', { variant: 'warning' });
                return
            }

            if (novaviagem.datainicio === null) {
                enqueueSnackbar('Defina uma data inicial.', { variant: 'warning' });
                return
            }

            setLoadingMessage("Criando sua viagem...")

            novaviagem.adiantamento.forEach(viagem => {
                if (typeof viagem.total === 'string') {
                    let totalString = viagem.total.replace(/\./g, '').replace(',', '.');
                    viagem.total = parseFloat(totalString);
                }
            });

            const response = await prestacaoDeContasRepositorio.putNovaViagem(novaviagem);

            enqueueSnackbar('Viagem criada com sucesso.', { variant: 'success' });

            setLoadingMessage("Buscando sua viagem...")

            const viagem = await prestacaoDeContasRepositorio.getViagem(response.id);

            setViagem(viagem)
            getMinhasViagens()
            setCriarNovaViagem(false)
            setOpenViagem(true)
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickViagem = (item) => {
        setLoading(true)
        setViagem(item)
        setOpenViagem(true)
        setLoading(false)
    }

    const handleClickEditViagem = async (startDate = null, endDate = null) => {
        setLoading(true)
        try {
            setAlterViagem({});

            let model = {
                datainicio: new Date(startDate ? startDate : viagem.datainicio),
                datafim: new Date(endDate ? endDate : viagem.datafim),
                titulo: alterViagem.titulo !== "" && alterViagem.titulo != null ? alterViagem.titulo : viagem.titulo
            }

            await prestacaoDeContasRepositorio.putEditViagem(viagem.id, model);
            enqueueSnackbar('Viagem Editada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar viagem.', { variant: 'error' });
        } finally {
            setAlterViagem({})
            setAlterTitulo(false)
            setLoading(false)
        }
    }


    const handleClickCriarDespesa = async (despesa) => {
        setLoading(true)
        setLoadingMessage("Adicionando sua despesa...")
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(despesa);
            enqueueSnackbar('Despesa criada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar gasto.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditDespesa = async (despesa) => {
        setLoading(true)
        setLoadingMessage("Editando sua despesa...")
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(despesa)
            enqueueSnackbar('Despesa editada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteDespesa = async (despesa) => {
        setLoading(true)
        setLoadingMessage("Deletando sua despesa...")
        try {
            await prestacaoDeContasRepositorio.deleteDespesa(despesa.id)
            enqueueSnackbar('Despesa excluida com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao excluida despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleIniciarViagem = async (idviagem) => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.postIniciarViagem(idviagem)
            setViagem(response)
            enqueueSnackbar('Despesa iniciada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao iniciar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleFinalizarViagem = async (idviagem) => {
        setLoading(true)
        setLoadingMessage("Avançando sua Despesa...")
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(idviagem)
            getMinhasViagens()
            getViagemForID()
            enqueueSnackbar('Despesa finalizada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao finalizada despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickExcluirViagem = async (idviagem) => {
        setLoading(true)
        setLoadingMessage("Excluindo sua despesa...")
        try {
            await prestacaoDeContasRepositorio.deleteViagem(idviagem)
            setOpenDeleteViagem(false)
            setOpenViagem(false)
            getMinhasViagens()
            enqueueSnackbar('Despesa deletada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao deletar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickCreateHodometro = async (hodometro) => {
        setLoading(true)
        setLoadingMessage("Adicionando seu hodometro...")
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(hodometro)
            enqueueSnackbar('Adicionado Hodometro.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao adicionar hodometro.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditHodometro = async (hodometro) => {
        setLoading(true)
        setLoadingMessage("Editando seu hodometro...")
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(hodometro)
            enqueueSnackbar('Editado Hodometro manual.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editadar hodometro.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMinhasViagens();
    }, [])

    useEffect(() => {
        FiltersViagem()
    }, [minhasviagens])

    return (
        <>
            <ULoaderVooMessage isLoading={loading} message={loadingMessage} />
            <Container maxWidth={"sm"} item xs={12} container style={{ marginBottom: "70px" }}>
                <Grid item xs={12}>
                    <CardInitialUser_Mob pessoa={pessoa} criarNovaviagem={criarNovaviagem} novaviagem={novaviagem} setNovaviagem={setNovaviagem} setCriarNovaViagem={setCriarNovaViagem} confirmCriarNovaViagem={confirmCriarNovaViagem} />
                </Grid> 
                <Grid container item xs={12}>
                    <Grid container item xs={10} style={{ marginTop: "20px" }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Pesquisar por título"
                            onChange={(e) => handlesearchname(e)}
                            placeholder="Pesquisar por título..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                borderRadius: "15px",
                                fontStyle: "inherit",
                                fontFamily: "sans-serif",
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', alignItems: "flex-end", justifyContent: "center" }}>
                        <FilterComponent_mob
                            openFilters={openFilters}
                            hasActiveStatus={hasActiveStatus}
                            setOpenFilters={setOpenFilters}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            statuses={statuses}
                            setStatuses={setStatuses}
                            applyFilters={applyFilters}
                        />
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    {currentItems?.map((item, index) => (
                        <>
                            <CardMyViagem_mob item={item} handleClickViagem={handleClickViagem} />
                        </>
                    ))}

                    {currentItems.length === 0 && (
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                            <img alt="Pastas imagem" src={IconsSourceSvg.pastas}></img>
                            <Typography variant={"body1"} style={{ color: "#818181", marginTop: "10px" }}>Nenhuma despesa encontrada</Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                        {totalPages > 1 && (
                            <Pagination
                                color="primary"
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                                siblingCount={0}
                                shape="rounded"
                            />
                        )}
                    </Grid>

                    <ViagemViewComponent_mob
                        handleChangenameViagem={handleChangenameViagem}
                        typeDialog={typeDialog}
                        viagem={viagem}
                        openViagem={openViagem}
                        handleCloseViagem={handleCloseViagem}
                        alterTitulo={alterTitulo}
                        handleClickEditViagem={handleClickEditViagem}
                        openDeleteViagem={openDeleteViagem}
                        setOpenDeleteViagem={setOpenDeleteViagem}
                        handleClickExcluirViagem={handleClickExcluirViagem}
                        temDespesa={temDespesa}
                        hasApprovedAdiantamento={hasApprovedAdiantamento}
                        loading={loading}
                        handleClickCriarDespesa={handleClickCriarDespesa}
                        handleClickEditDespesa={handleClickEditDespesa}
                        setTemDespesa={setTemDespesa}
                        setTypeDialog={setTypeDialog}
                        handleDeleteDespesa={handleDeleteDespesa}
                        handleIniciarViagem={handleIniciarViagem}
                        handleFinalizarViagem={handleFinalizarViagem}
                        handleClickCreateHodometro={handleClickCreateHodometro}
                        handleClickEditHodometro={handleClickEditHodometro}
                    />
                </Grid>
            </Container>
        </>
    )
}