import { Box, Button, ButtonGroup, Grid, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import Dashboard from "../dashboard";
import { useCallback, useEffect, useState } from "react";
import { Auditor } from "./Desktop/auditor";
import { MinhasViagens } from "./Desktop/minhasviagens";
import { MinhasViagensM } from "./Mobile/minhasviagens";
import { themepalette } from "../../theme/palette";
import { AuditorM } from "./Mobile/auditor";
import IntegrationNotistack from "../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../components/uloadervoo";
import { ConfigAll } from "./Desktop/config";
import { ConfigAllM } from "./Mobile/config";
import { prestacaoDeContasConfigRepositorio } from "../../repositorios/prestacaodecontas/config";

export const PrestacaoDeContas = () => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentTab, setCurrentTab] = useState("minhasviagens");
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        let isMounted = true; // Flag para verificar se o componente está montado

        const getMyPermission = async () => {
            setLoading(true);
            try {
                const response = await prestacaoDeContasConfigRepositorio.getPermissionUsuario();
                if (isMounted) { // Apenas atualiza o estado se o componente estiver montado
                    setPermissions(response.permissoes);
                }
            } catch (error) {
                console.error("Erro ao obter permissões", error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        getMyPermission();

        return () => {
            isMounted = false; // Define como false ao desmontar
        };
    }, []);

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    const TABSdesk = [
        ...(permissions?.permissao_contabilidade || permissions?.permissao_controladoria || permissions?.permissao_vertudo ? [{
            value: 'auditor',
            label: 'Auditar despesas',
            viewer: <Auditor permissions={permissions} />
        }] : []),
        {
            value: 'minhasviagens',
            label: 'Minhas despesas',
            viewer: <MinhasViagens />
        },
        ...(permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra ? [{
            value: 'configuracoes',
            label: 'Configuração',
            viewer: <ConfigAll permissions={permissions} />
        }] : []),
    ];

    const TABSmob = [
        ...(permissions?.permissao_contabilidade || permissions?.permissao_controladoria || permissions?.permissao_vertudo ? [{
            value: 'auditor',
            label: 'Auditar despesas',
            viewer: <AuditorM permissions={permissions} />
        }] : []),
        {
            value: 'minhasviagens',
            label: 'Minhas despesas',
            viewer: <MinhasViagensM />
        },
        ...(permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra ? [{
            value: 'configuracoes',
            label: 'Configuração',
            viewer: <ConfigAllM permissions={permissions} />
        }] : []),
    ];

    const renderTabs = (tabs) => (
        <ButtonGroup
            variant="outlined"
            color="inherit"
            style={{ justifyContent: "center", borderColor: "#D6D6D6", borderRadius: "10px" }}
        >
            {tabs.map((tab, index) => (
                <Button
                    key={tab.value}
                    onClick={() => handleChangeTab(tab.value)}
                    style={{
                        textTransform: "none",
                        fontWeight: currentTab === tab.value ? 600 : 400,
                        borderColor: currentTab === tab.value ? "#1C252E" : "#D6D6D6",
                        backgroundColor: currentTab === tab.value ? "#1C252E" : "#FFFFFF",
                        color: currentTab === tab.value ? "#FFFFFF" : "#1C252E",
                        borderRadius: index === 0 ? "8px 0 0 8px" : index === tabs.length - 1 ? "0 8px 8px 0" : "0"
                    }}
                >
                    {tab.label}
                </Button>
            ))}
        </ButtonGroup>
    );

    const renderContent = (tabs) => {
        const currentTabContent = tabs.find(tab => tab.value === currentTab);
        return currentTabContent ? currentTabContent.viewer : null;
    };

    return (
        <ThemeProvider theme={themepalette}>
            <IntegrationNotistack>
                <ULoaderVoo isLoading={loading} />
                <Grid container style={{ backgroundColor: "#FFFFFF" }}>
                    {!loading && (
                        <>
                            <Grid item xs={12} style={{ justifyContent: "center", textAlign: "center", marginBottom: "35px", marginTop: "35px" }}>
                                {permissions?.permissao_contabilidade || permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra || permissions?.permissao_vertudo
                                    ? renderTabs(fullscreen ? TABSmob : TABSdesk)
                                    : null}
                            </Grid>

                            <Grid item xs={12}>
                                {renderContent(fullscreen ? TABSmob : TABSdesk)}
                            </Grid>
                        </>
                    )}
                </Grid>
            </IntegrationNotistack>
        </ThemeProvider>
    );
}

export const PagePrestacaoDeContas = (props) => {
    return (
        <div>
            <Dashboard {...props} component={PrestacaoDeContas} />
        </div>
    );
};

export default PagePrestacaoDeContas;
