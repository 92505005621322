import {
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AddCircleOutline,
  IndeterminateCheckBoxOutlined,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import CustomizedSnackbars from "../../../../components/snackbar";
import ULoaderVoo from "../../../../components/uloadervoo";
import USearchField from "../../../../components/usearchfield";
import solicitacaoRepositorio from "../../../../repositorios/solicitacao";
import { useSelector } from "react-redux";

export const ModalProduto = (props) => {
  const theme = useTheme();
  const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selecionado, setSelecionado] = useState(null);
  const [tipoSnack, setTipoSnack] = useState("success");
  const [mensagemSnack, setMensagemSnack] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [snackbarSucess, setSnackbarSucess] = useState({
    visible: false,
  });

  const getNomeProduto = (idsolicitacaoproduto) => {
    let produto = props.solicitacao.produtos.find(
      (item) => item.idsolicitacaoproduto === idsolicitacaoproduto
    );
    return permissaoDashboard.usuarioLinestur ?
      produto.idsolicitacaoproduto ?
        produto.idsolicitacaoproduto + " - " + produto.produto.nome + " (" + produto.produto.fornecedor.nome + ")"
        : ""
      : produto.idsolicitacaoproduto ?
        produto.produto.nome
        : "";
  };

  const handleClickAdicionar = async () => {
    if (
      selecionado === null
      // ||
      // selecionado === {}
    ) {
      setMensagemSnack("Produto a ser vinculado não pode ser vazio!");
      setTipoSnack("error");
      setSnackbarSucess({ visible: true });
      return;
    }
    setBackDrop(true);
    let dtoProduto = {
      idsolicitacaodestino: props.destino.idsolicitacaodestino,
      idsolicitacaoproduto: selecionado.value,
      idtenant: props.destino.idtenant,
    };
    try {
      const response = await solicitacaoRepositorio.destinoProdutoAdicionar(
        dtoProduto
      );
      props.destino.produtos.push(response.data);
      setSelecionado(null);
      loadProdutos();
      setBackDrop(false);
      setMensagemSnack("Produto vinculado com sucesso!");
      setTipoSnack("success");
      setSnackbarSucess({ visible: true });
    } catch (e) {
      setBackDrop(false);
      setMensagemSnack("Erro ao vincular produto!");
      setTipoSnack("error");
      setSnackbarSucess({ visible: true });
      return;
    }
  };

  const handleClickRemover = async (item, index) => {
    setBackDrop(true);
    try {
      // let listaPassageiros = [];
      // const response =
      await solicitacaoRepositorio.destinoProdutoRemover(
        item.idsolicitacaodestinoproduto
      );
      props.destino.produtos.splice(index, 1);
      loadProdutos();
      setBackDrop(false);
      setMensagemSnack("Produto desvinculado com sucesso!");
      setTipoSnack("success");
      setSnackbarSucess({ visible: true });
    } catch (e) {
      setBackDrop(false);
      setMensagemSnack("Erro ao desvincular Produto!");
      setTipoSnack("error");
      setSnackbarSucess({ visible: true });
    }
  };

  const loadProdutos = useCallback(() => {
    let listaProdutos = props.solicitacao.produtos.map((item) => {
      return {
        label: item.produto.nome + " - " + item.produto.fornecedor.nome,
        value: item.idsolicitacaoproduto,
      };
    });
    props.destino.produtos.forEach((item) => {
      listaProdutos = listaProdutos.filter(
        (p) => p.value !== item.idsolicitacaoproduto
      );
    });
    setProdutos(listaProdutos);
  }, [props.solicitacao.produtos, props.destino.produtos]); // Dependências necessárias

  useEffect(() => {
    loadProdutos();
  }, [loadProdutos]);

  return (
    <div>
      <Grid container xs={12} spacing={2}>
        <CustomizedSnackbars
          {...{
            variant: tipoSnack,
            message: mensagemSnack,
            visible: snackbarSucess.visible,
            setSnackbar: setSnackbarSucess,
          }}
        />
        <ULoaderVoo isLoading={backDrop} />
        <Grid item xs={11}>
          <USearchField
            itens={produtos}
            value={selecionado}
            setValue={setSelecionado}
            label="Produto"
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Vincular Produto">
            <IconButton
              style={{ color: "#b91119" }}
              onClick={handleClickAdicionar}
            >
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        {fullScreen ? (
          <Grid container item xs={12} style={{ maxHeight: 300 }}>
            {props.destino.produtos.map((item, index) => (
              <Grid item xs={12}>
                <List>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Tooltip
                          title={getNomeProduto(item.idsolicitacaoproduto)}
                        >
                          <Typography noWrap>
                            {getNomeProduto(item.idsolicitacaoproduto)}
                          </Typography>
                        </Tooltip>
                      }
                    />
                    <ListItemIcon>
                      <Tooltip title="Desvincular Produto">
                        <IconButton
                          style={{ color: "#b91119" }}
                          onClick={() => handleClickRemover(item, index)}
                        >
                          <IndeterminateCheckBoxOutlined />
                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                  </ListItem>
                  <Divider />
                </List>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.destino.produtos.map((item, index) => (
                    <TableRow>
                      <TableCell>
                        {getNomeProduto(item.idsolicitacaoproduto)}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Desvincular Produto">
                          <IconButton
                            style={{ color: "#b91119" }}
                            onClick={() => handleClickRemover(item, index)}
                          >
                            <IndeterminateCheckBoxOutlined />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ModalProduto;
