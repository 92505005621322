import { Button, Grid, Typography } from "@mui/material"
import DialogComponent from "../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop"
import { IconsSource } from "../../../images/Icons"
import { EnumTipo, hasApprovedAdiantamento } from "../../../enums/enumPrestacaoDeContas"

export const CardAddInViagem_mob = ({
    viagem,
    despesas,
    openFinalizarDespesa,
    setOpenFinalizarDespesa,
    loading,
    pendente,
    handleClickFinalizarViagem,
    hasApprovedAdiantamento,
    setType
}) => {
    return (
        <>
            <Grid
                item xs={12}
                style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "10px", padding: "20px", width: "100%", maxWidth: 360 }}
            >
                <Grid style={{ minWidth: "200px", justifyContent: "center", textAlign: "center" }}>
                    {viagem !== null && despesas.length !== 0 && viagem.status !== EnumTipo.viagemstatus.pendenteacorrecao ? (
                        <Button variant="outlined" size="large" color="primary" fullWidth onClick={() => setOpenFinalizarDespesa(true)}>Finalizar despesa</Button>
                    ) : (null)}
                    {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && pendente && !loading && (
                        <Typography variant="body2" style={{ color: "#313131" }}>Há despesa(s) pendente a correção, retornado pelo auditor, corrija oque foi pedido para avançar sua viagem.</Typography>
                    )}
                    {viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !pendente && !loading && despesas.length !== 0 ? (
                        <Button variant="contained" size="large" color="primary" fullWidth onClick={() => setOpenFinalizarDespesa(true)}>Aplicar Correção</Button>
                    ) : (null)}
                    <DialogComponent
                        title={viagem?.titulo}
                        open={openFinalizarDespesa}
                        handleClose={() => setOpenFinalizarDespesa(false)}
                        body={
                            <Grid container item xs={12}>
                                <Typography>Ao avançar sua viagem não poderá retorna-la, apenas ver sua viagem</Typography>
                            </Grid>
                        }
                        action={
                            <Grid>
                                <Button onClick={() => setOpenFinalizarDespesa(false)}>Cancelar</Button>
                                <Button onClick={() => handleClickFinalizarViagem()}>Confimar</Button>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    {viagem.status === EnumTipo.viagemstatus.aguardandoinicio || viagem.status === EnumTipo.viagemstatus.emviagem || (viagem.status === EnumTipo.viagemstatus.pendenteacorrecao && !hasApprovedAdiantamento(viagem.adiantamento)) ? (
                        <>
                            <Button size="large" className="adicionardespesa_mob" fullWidth variant="contained" color="primary" onClick={() => setType(1)}>Adicionar gasto</Button>
                            <Button size="large" className="adicionarhodometro_mob" fullWidth startIcon={<img alt='Icon' src={IconsSource.googleMapIcon} width={20}></img>} variant="contained" color="secondary" onClick={() => setType(2)}>Hodômetro</Button>
                        </>
                    ) : (null)}

                </Grid>
            </Grid>
        </>
    )
}