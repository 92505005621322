import { Alert, Badge, Button, ButtonGroup, Checkbox, Chip, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { useStyles } from "./style";
import { CreationDate, IniciadoEm } from "../../sections";
import { getTipoDespesaForNumber } from "../../../../../mock/tiposdespesas";
import { ImageComponent } from "../../../../../components/ImageComponent";
import { useCallback, useEffect, useState } from "react";
import { excluirExtra_Mock } from "../../../../../mock/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import DialogComponent from "../../sections/dialogs-desktop";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { BuildOutlined, DeleteOutline, MarkEmailRead, NotificationImportant, QrCode2Outlined } from "@mui/icons-material";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { formatarValorComMoeda } from "../../../../../enums/enumPrestacaoDeContas";
import { DeskCardPersonExpanses } from "../../../../../components/card_person_expanses";
import { ExtraAddComponent } from "../Extras";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import ULoaderVoo from "../../../../../components/uloadervoo";
import { ViewHodometerExpansesComponent } from "../../../../../components/autalii_expanses/viewhodometer_expanses";
import { ViewQrCodeProducts } from "../../../../../components/autalii_expanses/viewqrcode";

export const AprovarDespesasComponent = ({ viagem, setViagem, setOpen, getViagensReload }) => {
    const classes = useStyles();
    const [despesasAgrupadas, setDespesasAgrupadas] = useState(null);
    const [totalSelecionadas, setTotalSelecionadas] = useState(0);
    const [openReturnDespesa, setOpenReturnDespesa] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [despesa, setDespesa] = useState(null);
    const [despesas, setDespesas] = useState([]);
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [openAuditar, setOpenAuditar] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [totalGasto, setTotalgasto] = useState(despesa?.total);
    const [openDeleteExtra, setOpenDeleteExtra] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([])
    const [openQrCodeView, setOpenQrCodeView] = useState(false)
    const [marcarTodas, setMarcarTodas] = useState(false);

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, setCategorias, enqueueSnackbar]); // Dependências

    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas);
            await getAlltipos();  // Aguarda a execução de getAlltipos
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, viagem.id, setDespesas, enqueueSnackbar, getAlltipos]);

    const [count, setCount] = useState(0)

    const reload = () => {
        setTotalSelecionadas(0)
        agruparDespesasPorTipo();
        setDescricao("")
    }

    const agruparDespesasPorTipo = useCallback(() => {
        const agrupadas = {};

        categorias?.forEach((element) => {
            despesas?.forEach((despesa) => {
                if (!despesa.aprovado && despesa.produtos.iddespesa_tipo === element.iddespesa_tipo) {
                    const tipoTitulo = element.name;
                    if (!agrupadas[tipoTitulo]) {
                        agrupadas[tipoTitulo] = [];
                    }
                    agrupadas[tipoTitulo].push({ ...despesa, selecionada: false });
                }
            });
        });

        setDespesasAgrupadas(Object.entries(agrupadas).map(([titulo, despesas]) => ({ titulo, despesas })));
    }, [categorias, despesas, setDespesasAgrupadas]);

    const toggleSelecaoDespesa = (tipoTitulo, indexDespesa) => {
        let contador = 0; // Inicializa contador para atualização do total de selecionadas

        setDespesasAgrupadas(despesasAgrupadas.map(grupo => {
            if (grupo.titulo === tipoTitulo) {
                return {
                    ...grupo,
                    despesas: grupo.despesas.map((despesa, index) => {
                        if (index === indexDespesa) {

                            const despesaAtualizada = { ...despesa, selecionada: !despesa.selecionada };
                            // Se a despesa for selecionada, incrementa, senão decrementa
                            contador += despesaAtualizada.selecionada ? 1 : -1;
                            return despesaAtualizada;
                        }
                        // Conta as já selecionadas
                        if (despesa.selecionada) contador++;
                        return despesa;
                    }),
                };
            }
            // Conta as selecionadas em outros grupos
            grupo.despesas.forEach(despesa => {
                if (despesa.selecionada) contador++;
            });
            return grupo;
        }));

        setTotalSelecionadas(contador);
    };

    const toggleSelecaoTodasDespesas = () => {
        let contador = 0; // Inicializa contador para atualização do total de selecionadas

        // Atualiza o estado das despesas agrupadas
        setDespesasAgrupadas(despesasAgrupadas.map(grupo => {
            return {
                ...grupo,
                despesas: grupo.despesas.map(despesa => {
                    // Seleciona ou desmarca todas de acordo com o estado de marcarTodas
                    const selecionada = !marcarTodas;
                    contador += selecionada ? 1 : 0; // Incrementa contador apenas se for selecionada
                    return { ...despesa, selecionada };
                }),
            };
        }));

        // Atualiza o contador de despesas selecionadas
        setTotalSelecionadas(contador);

        // Atualiza o estado de marcarTodas para alternar o comportamento do Checkbox
        setMarcarTodas(!marcarTodas);
    };

    const handleClickAprovar = async () => {
        setLoading(true)
        try {
            let list = []
            despesasAgrupadas.forEach(element => {
                element.despesas.forEach(item => {
                    if (item.selecionada) {
                        list.push(item.iddespesa)
                        // aprovarDespesas_Mock(viagem.id, item.id)
                    }
                })
            });

            await prestacaoDeContasRepositorio.postAprovarDespesas(viagem.id, list)
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Aprovada com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar aprovar despesa(s).', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    const handleClickAuditar = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putAuditarValor(viagem.id, despesa.iddespesa, totalGasto);
            getDespesas()
            getViagensReload()
            setOpenAuditar(false)
            enqueueSnackbar('Valor auditado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao auditar a despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleClickReturn = (despesa) => {
        setDespesa(despesa)
        setOpenReturnDespesa(true)
    }

    const handleOpenEdit = (value) => {
        setDespesa(value)
        setOpenAuditar(true)
    }

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    const handleClickExcluirExtra = () => {
        excluirExtra_Mock(viagem.id, despesa.id)
        setOpenDeleteExtra(false)
        setCurrentTab(1)
        enqueueSnackbar('Removido com sucesso.', { variant: 'success' });
        reload()
    }

    const handleClickAprovarViagem = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(viagem.id)
            getDespesas()
            getViagensReload()
            setOpen(false)
            enqueueSnackbar('Avançada para o financeiro com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar avançar a viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickRetornarDespesaComMensagem = async () => {
        setLoading(true)
        try {
            despesa.mensagem = descricao
            await prestacaoDeContasRepositorio.putEnviarParaPessoa(viagem.id, [despesa])
            getDespesas()
            getViagensReload()
            setOpenReturnDespesa(false)
            enqueueSnackbar('Enviado para pessoa com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao enviar despesa para pessoa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickControladoriaDespesa = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putEnviarControladoria(viagem.id, despesa.iddespesa, descricao)
            getDespesas()
            getViagensReload()
            setOpenReturnDespesa(false)
            enqueueSnackbar('Enviado para controladoria com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro enviar para controladoria.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        let c = 0;
        viagem?.extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [viagem])

    useEffect(() => {
        getDespesas()
    }, [getDespesas])

    useEffect(() => {
        agruparDespesasPorTipo()
    }, [despesas, categorias, agruparDespesasPorTipo]);

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <DialogComponent
                title={
                    <>
                        Controladoria
                    </>
                }
                open={openAuditar}
                handleClose={() => setOpenAuditar(false)}
                body={
                    <>
                        <Grid item xs={12} style={{ padding: "5px" }}>
                            <TextField
                                fullWidth
                                id="outlined-number"
                                label="Total gasto"
                                defaultValue={despesa?.total || 0}
                                onChange={(e) => setTotalgasto(e.target.value)}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </>
                }
                action={
                    <>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                            <Button variant="contained" color="primary" autoFocus onClick={() => handleClickAuditar()}>
                                Auditar Total
                            </Button>
                        </Grid>
                    </>
                }
                width={"sm"}
            />
            <DialogComponent
                title={
                    <>
                        Informações do QrCode
                    </>
                }
                open={openQrCodeView}
                handleClose={() => setOpenQrCodeView(false)}
                body={
                    <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                }
                action={
                    <></>
                }
                width={"xs"}
            />
            {despesa?.hodometro != null ? (
                <DialogComponent
                    title={
                        <>
                            Informações do Hodômetro
                        </>
                    }
                    open={openInfoHodometro}
                    handleClose={() => setOpenInfoHodometro(false)}
                    body={
                        <ViewHodometerExpansesComponent despesa={despesa} />
                    }
                    action={
                        <></>
                    }
                    width={"sm"}
                />
            ) : (null)}
            <Grid container item xs={12} style={{ width: "600px" }}>
                <Grid item xs={12} container style={{ textAlign: "center" }}>
                    <Grid item xs={12} container style={{ marginTop: "0px" }}>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IniciadoEm datainicio={viagem.datainicio} />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", }}>
                            <Grid style={{ marginLeft: "auto" }}>
                                <CreationDate data={viagem.datadecriacao} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            <DeskCardPersonExpanses viagem={viagem} despesas={despesas} />
                        </Grid>
                    </Grid>
                    {viagem !== null && count !== 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Conciliação" }].map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}
                    {currentTab === 1 && (
                        <>
                            {despesasAgrupadas?.length > 0 ? (
                                <Grid item xs={12} container>
                                    <Grid item xs={12} style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        marginTop: "auto"
                                    }}>
                                        <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">Aprovar Todos</Typography>
                                        <Checkbox
                                            color="primary"
                                            checked={marcarTodas} // Controlado pela variável de controle
                                            onChange={toggleSelecaoTodasDespesas} // Alterna a seleção de todas as despesas
                                        />
                                    </Grid>
                                    {despesasAgrupadas.map((grupo, indexGrupo) => (
                                        <Grid item xs={12} key={indexGrupo}>
                                            <h3>{grupo.titulo}</h3>
                                            {grupo.despesas.map((item, indexDespesa) => (
                                                <>
                                                    <Grid
                                                        item xs={12}
                                                        className={classes.card}
                                                        style={{ marginTop: "20px" }}
                                                        container
                                                    >
                                                        {item.hodometro ? (
                                                            <>
                                                                <Grid container item xs={12} style={{ padding: "20px" }}>
                                                                    <Grid container item xs={6}>
                                                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                            <Typography style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                        <Grid style={{ marginLeft: "auto" }}>
                                                                            <CreationDate data={item.datadecompra} />
                                                                        </Grid>
                                                                        {item.totalauditado !== null ? (
                                                                            <>
                                                                                <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.totalauditado))} color="warning" style={{ marginTop: "-14px", marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeIcon />}>
                                                                                </Chip>
                                                                            </>) : (null)}
                                                                    </Grid>
                                                                    <Grid item xs={8} style={{ textAlign: "left" }}>
                                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4} style={{ display: "flex", justifyContent: "right" }}>
                                                                        <Button
                                                                            startIcon={<DirectionsCarIcon />}
                                                                            color="secondary"
                                                                            onClick={() => handleClickOpenHodometro(item)}
                                                                        >
                                                                            Ver Informações
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={8} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignContent: "flex-start",
                                                                        alignItems: "center",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        {item.mensagem !== "" ? (
                                                                            <>
                                                                                {item.controladoria ? (
                                                                                    <Button color="warning" style={{ marginTop: "-18px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AdminPanelSettingsIcon />}>
                                                                                        Notificado
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Tooltip title="O viajante já foi notificado sobre a despesa">
                                                                                        <Button color="secondary" style={{ marginTop: "-18px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                                                            Viajante notificado
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <Button color="secondary" style={{ marginTop: "auto", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                                                Retornar
                                                                            </Button>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={4} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "center",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        {item.mensagem === "" ? (
                                                                            <>
                                                                                <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">Aprovar</Typography>
                                                                                <Checkbox
                                                                                    defaultChecked
                                                                                    color="primary"
                                                                                    checked={item.selecionada}
                                                                                    onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                                                                />
                                                                            </>
                                                                        ) : (null)}
                                                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{item.hodometro.kmtotal} Km</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid container item xs={10} style={{ padding: "20px" }}>
                                                                    <Grid container item xs={6}>
                                                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                            <Typography style={{ fontWeight: "600" }}>Gasto</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container item xs={6} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                                        <Grid style={{ marginLeft: "auto" }}>
                                                                            <CreationDate data={item.datadecompra} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={8} style={{ textAlign: "left" }}>
                                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={4} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                                        {item.qrCode !== null && (
                                                                            <Grid>
                                                                                <IconButton onClick={() => handleClickOpenQrCode(item)}>
                                                                                    <QrCode2Outlined />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        )}
                                                                        {item.valor_auditado !== 0 ? (
                                                                            <>
                                                                                <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor_auditado))} color="warning" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeIcon />}>
                                                                                </Chip>
                                                                            </>) : (null)}
                                                                    </Grid>
                                                                    <Grid item xs={8} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                        alignContent: "flex-start",
                                                                        alignItems: "center",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        {item.mensagem !== "" ? (
                                                                            <>
                                                                                {item.controladoria ? (
                                                                                    <Button color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AdminPanelSettingsIcon />}>
                                                                                        notificado
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Tooltip title="O viajante já foi notificado sobre a despesa">
                                                                                        <Button color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                                                            Viajante notificado
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <Button color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                                                retornar
                                                                            </Button>
                                                                        )}
                                                                        <Tooltip title="Corrigir o valor da despesa">
                                                                            <Button color="secondary" style={{ marginRight: "3px", borderRadius: "20px" }} onClick={() => handleOpenEdit(item)} startIcon={<BuildOutlined />}>
                                                                                Corrigir
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={4} style={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        alignContent: "flex-end",
                                                                        alignItems: "center",
                                                                        flexDirection: "row",
                                                                        marginTop: "auto"
                                                                    }}>
                                                                        {item.mensagem === "" ? (
                                                                            <>
                                                                                <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">aprovar</Typography>
                                                                                <Checkbox
                                                                                    defaultChecked
                                                                                    color="primary"
                                                                                    checked={item.selecionada}
                                                                                    onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                                                                />
                                                                            </>
                                                                        ) : (null)}
                                                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Badge
                                                                        color="error"
                                                                        badgeContent={
                                                                            <Tooltip title="Fora da política">
                                                                                <NotificationImportant fontSize="small" style={{ color: "#FFFFFF" }} />
                                                                            </Tooltip>
                                                                        }
                                                                        invisible={item.is_fora_politica !== null && item.is_fora_politica ? false : true}
                                                                    // invisible={false}
                                                                    >
                                                                        <ImageComponent width={100} height={148} src={item.comprovante} alt={"Imagem não encontrada"} />
                                                                    </Badge>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    {(item.limite > 0 && item.total > item.limite) && (
                                                        <Alert severity="info" style={{ marginTop: "10px", fontWeight: "500", borderRadius: "10px" }}>
                                                            Essa despesa passou do limite permitido no grupo {item.despesa_desc} de
                                                            <strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.limite)} </strong>
                                                            ela gastou
                                                            <strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}. </strong>
                                                        </Alert>
                                                    )}
                                                </>
                                            ))}
                                            <DialogComponent
                                                title={
                                                    <>
                                                        Mensagem
                                                    </>
                                                }
                                                open={openReturnDespesa}
                                                handleClose={() => setOpenReturnDespesa(false)}
                                                body={
                                                    <Grid container item xs={12}>
                                                        <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>Envie a descrição do erro dessa despesa para a controladoria ou de volta para a pessoa</Typography>
                                                        <TextField
                                                            id="filled-multiline-flexible"
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            defaultValue={despesa?.mensagem}
                                                            onChange={(e) => setDescricao(e.target.value)}
                                                            disabled={despesa?.mensagem !== "" ? true : false}
                                                            multiline
                                                            maxRows={4}
                                                            label="Justificativa/Descrição"
                                                            placeholder="Tem algo de errado com..."
                                                        />
                                                    </Grid>
                                                }
                                                action={
                                                    <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                                        {despesa?.mensagem !== "" ? (
                                                            <>
                                                            </>
                                                            // <Button color="error" onClick={() => handleClickCancelarEnvioDeMensagem(despesa?.id)}>Cancelar</Button>
                                                        ) : (
                                                            <>
                                                                <Button color="warning" disabled={descricao !== "" ? false : true} onClick={() => handleClickControladoriaDespesa(despesa?.id)}>Controladoria</Button>
                                                                <Button disabled={descricao !== "" ? false : true} onClick={() => handleClickRetornarDespesaComMensagem(despesa?.id)}>Pessoa</Button>
                                                            </>
                                                        )}
                                                    </Grid>
                                                }
                                                width={"sm"}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <>

                                </>
                            )}
                        </>
                    )}
                </Grid>
                {currentTab === 2 && (
                    <>
                        <Grid item xs={12}>
                            <ExtraAddComponent viagem={viagem} />
                        </Grid>
                        <DialogComponent
                            title={
                                <>
                                    Excluir extra?
                                </>
                            }
                            open={openDeleteExtra}
                            handleClose={() => setOpenDeleteExtra(false)}
                            body={
                                <Grid container item xs={12} width={280}>
                                    <Typography>Deseja realmente excluir o extra?</Typography>
                                </Grid>
                            }
                            action={
                                <Grid>
                                    <Button onClick={() => handleClickExcluirExtra()}>Confirmar</Button>
                                </Grid>
                            }
                            width={"sm"}
                        />
                        {viagem?.extras?.map((value) => (
                            <>
                                <Grid
                                    item xs={12}
                                    container
                                    style={{
                                        marginTop: "5px",
                                        padding: "20px",
                                        transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                                        cursor: 'default', // Cursor padrão quando não está em hover
                                        backgroundColor: "#FFFFFF",
                                        textAlign: 'center',
                                        borderRadius: "10px",
                                        boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    }}
                                >
                                    <Grid container item xs={12}>
                                        <Grid container item xs={6} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                            <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("hodômetro") : ("Gasto")}</Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                            <IconButton onClick={() => { setOpenDeleteExtra(true); setDespesa(value); }}>
                                                <DeleteOutline />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: "left", marginTop: "8px" }}>
                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "row"
                                    }}>
                                        {getTipoDespesaForNumber(value.tipo, value.produtos.value)}
                                    </Grid>
                                    <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                        <CreationDate data={value.datadecriacao} />
                                    </Grid>
                                    <Grid item xs={3} style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end",
                                        flexDirection: "row"
                                    }}>
                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </>
                )}
                {despesasAgrupadas?.length > 0 ? (
                    <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="contained" color="primary" disabled={totalSelecionadas > 0 ? false : true} onClick={() => handleClickAprovar()}>Aprovar</Button>
                    </Grid>
                ) : (
                    <>
                        {!loading && despesasAgrupadas !== null && despesasAgrupadas?.length === 0 && (
                            <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                                <Button variant="contained" color="primary" fullWidth onClick={() => handleClickAprovarViagem()}>Avançar para o financeiro</Button>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    )
}