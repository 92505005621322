import { useLayoutEffect, useState } from "react";
import { Button, ButtonGroup, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, TextField, Typography } from "@mui/material";
import { grey } from "../../../../../theme/palette";
import DateDifference from "../../sections/custom-date-difference";
import UtaliiDatePickerInput from "../../sections/custom-date-picker-start_and_end";
import { Add, Delete, EditOutlined } from "@mui/icons-material";
import centroCustoRepositorio from "../../../../../repositorios/centroCusto";
import { EnumTipo, formatarMoeda, getCurrencySymbol } from "../../../../../enums/enumPrestacaoDeContas";
import Flag from "react-world-flags";

export const NovaViagemComponentTutorial = ({ model }) => {
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [datainicio, setDataInicio] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [datafim, setDataFim] = useState(new Date(new Date().getFullYear(), 11, 31));
    const [centrodecusto, setcentrodecusto] = useState(model.id_centrocusto || 1);
    const [currentTab, setCurrentTab] = useState("Nacional");
    const gerenciamento = "com";
    const [moedaValorCampos, setMoedaValorCampos] = useState([
        { tipo_moeda: EnumTipo.moedas.BRL.id, total: 1500, status: EnumTipo.adiantamento.solicitado }
    ]);

    const handleDateChange = (newValue) => {
        setDataInicio(newValue[0].toDate());
        setDataFim(newValue[1].toDate());
    };

    return (
        <>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} style={{ marginTop: "2px" }}>
                    <TextField
                        className="element2"
                        variant="outlined"
                        fullWidth
                        label="Título da despesa"
                        value={model.titulo || "Despesa para Porto Alegre"}
                        placeholder="Despesa para..."
                        style={{ borderRadius: "15px" }}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth className="element3">
                        <InputLabel id="demo-simple-select-label">Centro de Custo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={centrodecusto}
                            label="Centro de custo"
                        >
                            <MenuItem value={1}>Centro de custo - 00001</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid className="element4" item style={{ marginTop: "-10px", display: "flex" }}>
                    <UtaliiDatePickerInput
                        value={selectedDate}
                        onChange={handleDateChange}
                        fullWidth
                        readOnly
                    />
                </Grid>
                <Grid item xs={12}>
                    <DateDifference startDate={datainicio} endDate={datafim} />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: "15px", marginTop: "-5px", display: "flex", justifyContent: "center" }}>
                    <ButtonGroup
                        variant="outlined"
                        color="inherit"
                        style={{ justifyContent: "center", borderColor: "#D6D6D6", borderRadius: "10px" }}
                    >
                        <Button
                            size="large"
                            style={{
                                textTransform: "none",
                                fontWeight: currentTab === 'Nacional' ? 600 : 400,
                                borderColor: currentTab === 'Nacional' ? "#1C252E" : "#D6D6D6",
                                backgroundColor: currentTab === 'Nacional' ? "#1C252E" : "#FFFFFF",
                                color: currentTab === 'Nacional' ? "#FFFFFF" : "#1C252E",
                                borderRadius: "8px 0 0 8px",
                                pointerEvents: "none" // Desabilita a interação
                            }}
                        >
                            Nacional
                        </Button>
                        <Button
                            size="large"
                            style={{
                                textTransform: "none",
                                fontWeight: currentTab === 'Internacional' ? 600 : 400,
                                borderColor: currentTab === 'Internacional' ? "#1C252E" : "#D6D6D6",
                                backgroundColor: currentTab === 'Internacional' ? "#1C252E" : "#FFFFFF",
                                color: currentTab === 'Internacional' ? "#FFFFFF" : "#1C252E",
                                borderRadius: "0 8px 8px 0",
                                pointerEvents: "none" // Desabilita a interação
                            }}
                        >
                            Internacional
                        </Button>
                    </ButtonGroup>
                </Grid>

                {currentTab === 'Nacional' && (
                    <Grid container item xs={12} style={{ marginTop: "-10px" }} className="element5">
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Total inicial
                            </Typography>
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                                R$
                                <TextField
                                    value={1500}
                                    inputProps={{
                                        step: 0.1,
                                        min: 0,
                                        max: 10000,
                                        type: 'number',
                                        readOnly: true,
                                        style: { fontSize: '28px', fontWeight: 'bold' }
                                    }}
                                    variant="standard"
                                    style={{ width: '100px', fontSize: '28px', fontWeight: 'inherit' }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "right", textAlign: "right", flexDirection: "column", alignItems: "flex-end" }}>
                            <FormGroup>
                                <FormControlLabel value={true} label="Pedir adiantamento" control={<Checkbox checked={true} disabled />} />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0px 20px 0px 20px" }}>
                            <Slider
                                marks
                                value={1500}
                                step={100}
                                max={10000}
                                min={0}
                                size="medium"
                                disabled
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export const ConfirmButtonNovaViagemTutorial = ({ confirmFunction }) => {
    return (
        <>
            <Button variant="contained" color="primary" fullWidth autoFocus onClick={() => confirmFunction()} className="element6" disabled>
                Finalizar
            </Button>
        </>
    );
};
