import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, TextField, Button, ButtonGroup, List, ListItem, ListItemText, ListItemSecondaryAction, FormGroup, FormControlLabel, Switch, IconButton, Typography } from "@mui/material";
import UtaliiDatePickerInput from "../../../views/prestacaodecontas/Desktop/sections/custom-date-picker-start_and_end";
import { SearchOutlined, TuneRounded } from "@mui/icons-material";
import { subDays, subMonths, subYears, startOfToday } from 'date-fns';
import dayjs from 'dayjs';  // Import dayjs
import { EnumTipo, formatarParaNomeStatusViagem, formatarParaNomeStatusViagemDescription } from '../../../enums/enumPrestacaoDeContas';
import { Badge } from 'reactour';
import DialogComponent from '../../../views/prestacaodecontas/Desktop/sections/dialogs-desktop';

export const FiltersViagens = ({ selectedDate, handleDateChange, statuses, setStatuses }) => {
    const today = dayjs(startOfToday());  // Convert today to a dayjs object

    // State to track the currently selected button
    const [currentTab, setCurrentTab] = useState("Hoje");

    const handleChangePermission = (key) => {
        setStatuses((prevStatuses) => ({
            ...prevStatuses,
            [key]: !prevStatuses[key],
        }));
    };

    // Define the date selection handlers with dayjs conversion and set active tab
    const setToday = () => {
        handleDateChange([today, today]);
        setCurrentTab("Hoje");
    };

    const setOneWeek = () => {
        handleDateChange([dayjs(subDays(today.toDate(), 7)), today]);
        setCurrentTab("Semana");
    };

    const setOneMonth = () => {
        handleDateChange([dayjs(subMonths(today.toDate(), 1)), today]);
        setCurrentTab("Mês");
    };

    const setOneYear = () => {
        handleDateChange([dayjs(subYears(today.toDate(), 1)), today]);
        setCurrentTab("Ano");
    };

    return (
        <>
            <Grid item xs={12} style={{ justifyContent: "center", textAlign: "center", marginBottom: "10px", marginTop: "10px" }}>
                <ButtonGroup size="medium" variant="outlined" color="inherit" style={{ borderColor: "#D6D6D6", borderRadius: "10px" }}>
                    <Button
                        onClick={setToday}
                        style={{
                            textTransform: "none",
                            fontWeight: currentTab === "Hoje" ? 600 : 400,
                            borderColor: currentTab === "Hoje" ? "#1C252E" : "#D6D6D6",
                            backgroundColor: currentTab === "Hoje" ? "#1C252E" : "#FFFFFF",
                            color: currentTab === "Hoje" ? "#FFFFFF" : "#1C252E",
                            borderRadius: "8px 0 0 8px"  // Rounded corners for the left button
                        }}
                    >
                        Hoje
                    </Button>
                    <Button
                        onClick={setOneWeek}
                        style={{
                            textTransform: "none",
                            fontWeight: currentTab === "Semana" ? 600 : 400,
                            borderColor: currentTab === "Semana" ? "#1C252E" : "#D6D6D6",
                            backgroundColor: currentTab === "Semana" ? "#1C252E" : "#FFFFFF",
                            color: currentTab === "Semana" ? "#FFFFFF" : "#1C252E",
                        }}
                    >
                        1 Semana
                    </Button>
                    <Button
                        onClick={setOneMonth}
                        style={{
                            textTransform: "none",
                            fontWeight: currentTab === "Mês" ? 600 : 400,
                            borderColor: currentTab === "Mês" ? "#1C252E" : "#D6D6D6",
                            backgroundColor: currentTab === "Mês" ? "#1C252E" : "#FFFFFF",
                            color: currentTab === "Mês" ? "#FFFFFF" : "#1C252E",
                        }}
                    >
                        1 Mês
                    </Button>
                    <Button
                        onClick={setOneYear}
                        style={{
                            textTransform: "none",
                            fontWeight: currentTab === "Ano" ? 600 : 400,
                            borderColor: currentTab === "Ano" ? "#1C252E" : "#D6D6D6",
                            backgroundColor: currentTab === "Ano" ? "#1C252E" : "#FFFFFF",
                            color: currentTab === "Ano" ? "#FFFFFF" : "#1C252E",
                            borderRadius: "0 8px 8px 0"  // Rounded corners for the right button
                        }}
                    >
                        1 Ano
                    </Button>
                </ButtonGroup>
            </Grid>

            <Grid container item xs={12}>
                <Grid item xs={12} style={{ marginTop: "7px" }}>
                    <UtaliiDatePickerInput
                        value={selectedDate}           // Pass selectedDate as value
                        onChange={handleDateChange}     // Pass handleDateChange as onChange
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: "20px" }}>
                <List style={{ width: '100%' }}>
                    {Object.keys(EnumTipo.viagemstatus).map((key) => (
                        <ListItem
                            key={key}
                            style={{
                                boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)',
                                borderRadius: '10px',
                                padding: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            <ListItemText
                                primary={formatarParaNomeStatusViagem(EnumTipo.viagemstatus[key])}
                                secondary={formatarParaNomeStatusViagemDescription(EnumTipo.viagemstatus[key])}
                                style={{ maxWidth: '70%' }}
                            />
                            <ListItemSecondaryAction>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={statuses[key]}
                                                onChange={() => handleChangePermission(key)}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </>
    );
};


const FiltersButton = ({ statuses, onApplyFilters, selectedDate }) => {
    // State to track if any change has been made
    const [hasChanges, setHasChanges] = useState(false);

    // Effect to detect any change in statuses or selectedDate
    useEffect(() => {
        // Check if any status is different from false (default)
        const hasActiveStatus = Object.values(statuses).some((value) => value);

        // Check if a valid date range is selected
        const hasSelectedDate = selectedDate && selectedDate[0] && selectedDate[1];

        // Set hasChanges to true if any status is active or date range is selected
        if (hasActiveStatus || hasSelectedDate) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [statuses, selectedDate]); // Re-run whenever statuses or selectedDate change

    return (
        <>
            <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={onApplyFilters}
            >
                Aplicar filtros
            </Button>
        </>
    );
};

export default FiltersButton;


export const FilterComponent_mob = ({ openFilters, hasActiveStatus, setOpenFilters, selectedDate, setSelectedDate, statuses, setStatuses, applyFilters }) => {
    return (
        <>
            {/* <Badge color="primary" variant="dot" invisible={!hasActiveStatus}> */}
            <IconButton variant="contained" onClick={() => setOpenFilters(true)}>
                <TuneRounded fontSize="large" />
            </IconButton>
            {/* </Badge> */}
            <DialogComponent
                title={<Grid container item xs={12}>
                    <Grid container item xs={6}>
                        <Typography style={{ fontWeight: "600" }}>Filtrar despesas</Typography>
                    </Grid>
                </Grid>}
                open={openFilters}
                handleClose={() => setOpenFilters(false)}
                fullScreen={true}
                body={<FiltersViagens selectedDate={selectedDate} handleDateChange={setSelectedDate} statuses={statuses} setStatuses={setStatuses} />}
                action={<FiltersButton statuses={statuses} onApplyFilters={applyFilters} selectedDate={selectedDate} />}
                width={"sm"}
            />
        </>
    )
}
