import React, { useState, useLayoutEffect } from 'react';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import SearchIcon from '@mui/icons-material/Search';
import CustomPopover, { usePopover } from '../../../../../components/custom-popover';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ptBR } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ExportExcel from '../../../../../components/UExportExcel';
import { Autocomplete, FormControl, InputLabel, Select } from '@mui/material';
import centroCustoRepositorio from '../../../../../repositorios/centroCusto';
import usuarioRepositorio from '../../../../../repositorios/usuario';

// ----------------------------------------------------------------------

export default function OrderTableToolbar({ data, filters, setFilters, handleClickSearch }) {
  const colunasExcel = [];
  const dataExcel = [];
  const [dateError, setDateError] = useState(false);
  const [centrodecusto, setcentrodecusto] = useState(0);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [pessoa, setPessoa] = useState(null);

  useLayoutEffect(() => {
    (async () => {
      const responsePessoas = await usuarioRepositorio.getAll();
      const response = await centroCustoRepositorio.getSearchField();

      setCentrosCusto(response);
      const mappedPessoas = responsePessoas.data.map(pessoa => ({
        label: pessoa.pessoa.nome, // Assumindo que `pessoa.pessoa.nome` contém o nome que você deseja exibir
        value: pessoa.pessoa.idpessoa, // Assumindo que `pessoa.pessoa.idpessoa` é o valor único da pessoa
        ...pessoa.pessoa, // Mapeie outras propriedades se necessário
      }));

      setPessoas(mappedPessoas);
    })()
  }, [])

  const handleChangeCentrodecusto = (event) => {
    setcentrodecusto(event.value);
    setFilters((prevFilters) => ({ ...prevFilters, centrodecusto: event.value }));
  };

  const handleChangePessoa = (event, newValue) => {
    if (newValue) {
      setPessoa({ label: newValue.label, value: newValue.value });
      setFilters((prevFilters) => ({ ...prevFilters, idpessoa: newValue.idpessoa }));
    } else {
      setPessoa(null);
      setFilters((prevFilters) => ({ ...prevFilters, idpessoa: 0 }));
    }
  };

  const popover = usePopover();

  const handleFilterStartDate = (newValue) => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: newValue }));
  }

  const handleFilterEndDate = (newValue) => {
    const hasError = filters.startDate && newValue && newValue < filters.startDate;
    setDateError(hasError);
    if (!hasError) {
      setFilters((prevFilters) => ({ ...prevFilters, endDate: newValue }));
    }
  }

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DatePicker
            label="Data inicial"
            value={filters.startDate}
            onChange={handleFilterStartDate}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            sx={{
              maxWidth: { md: 200 },
            }}
          />

          <DatePicker
            label="Data final"
            value={filters.endDate}
            onChange={handleFilterEndDate}
            slotProps={{
              textField: {
                fullWidth: true,
                error: dateError,
                helperText: dateError && 'Data final não pode ser maior que a data inicial',
              },
            }}
            sx={{
              maxWidth: { md: 200 },
              [`& .${formHelperTextClasses.root}`]: {
                position: { md: 'absolute' },
                bottom: { md: -40 },
              },
            }}
          />
        </LocalizationProvider>

        <FormControl style={{ width: "50%" }}>
          <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={centrodecusto}
            defaultValue={0}
            label="Centro de custo"

          >
            <MenuItem value={0} onClick={() => handleChangeCentrodecusto({ label: "Todos", value: 0 })}>Todos</MenuItem>
            {centrosCusto?.map((e) => (
              <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>

          <FormControl fullWidth>
            <Autocomplete
              options={pessoas}
              getOptionLabel={(option) => option.label}
              value={pessoa}
              onChange={handleChangePessoa}
              renderInput={(params) => <TextField {...params} label="Pessoa" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </FormControl>

          <IconButton onClick={() => handleClickSearch()}>
            <SearchIcon />
          </IconButton>

        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <ExportExcel
          name="Divergência"
          columns={colunasExcel}
          data={dataExcel}
          element={<MenuItem><GetAppIcon /> Exportar</MenuItem>}
        />
      </CustomPopover>
    </>
  );
}
