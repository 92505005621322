import React, { useEffect, useState } from "react";
import Dashboard from "../dashboard";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { HotelDesktop } from "./Desktop";
import { HotelMobile } from "./Mobile";
import hotelRepositorio from "../../repositorios/hotel";
import ULoaderVoo from "../../components/uloadervoo";
import pessoaRepositorio from "../../repositorios/pessoa";
import centroCustoRepositorio from "../../repositorios/centroCusto";
import usuarioRepositorio from "../../repositorios/usuario";
import { useSelector } from "react-redux";
import validatorCPF from "../../utils/cpfValidator";
import CustomizedSnackbars from "../../components/snackbar";

const HotelPage = (props) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paxGroup, setPaxGroup] = useState([]);
    const [checkin, setCheckin] = useState(() => {
        const nextDay = new Date();
        nextDay.setDate(new Date().getDate() + 1);
        return nextDay;
    });
    const [checkout, setCheckout] = useState(() => {
        const nextDay = new Date(checkin);
        nextDay.setDate(checkin.getDate() + 1);
        return nextDay;
    });
    const [locationid, setLocationid] = useState(null);
    const [listHotel, setListHotel] = useState(null);
    const [orderprice, setOrderprice] = useState({
        label: "Nenhum filtro",
        value: 0
    });
    const [locationUser, setLocationUser] = useState(null);
    const [solicitantes, setSolicitantes] = useState([]);
    const [solicitante, setSolicitante] = useState(null);
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const [step, setStep] = useState(1);
    const [orderprices, setOrderprices] = useState(
        [
            {
                label: "Preço (mais baixo)",
                value: 1
            },
            {
                label: "Preço (mais alta)",
                value: 2
            }
        ]);
    const [hoteis, setHoteis] = useState([
        {
            label: "Criciuma",
            value: 1
        }
    ]);

    const [roomsForHotel, setRoomsForHotel] = useState([
        {
            adultos: 1,
            crianças: []
        },
    ]);

    const [stepperAdultos, setStepperAdultos] = useState(1);
    const [stepperCriancas, setStepperCriancas] = useState(0);
    const [stepperRooms, setStepperRooms] = useState({
        value: 1,
        label: 'Quarto(s)',
        minValue: 1,
        maxValue: 9
    });
    const [hotel, setHotel] = useState(null);
    const [hotelprices, setHotelprices] = useState(null);
    const [hotelpricesRooms, setHotelPricesRooms] = useState([]);
    const [roomSelections, setRoomSelections] = useState([]);
    const [typeRooms, setTypeRooms] = useState([]);
    const [hotelrules, setHotelrules] = useState(null);
    const [pessoas, setPessoas] = useState(null)
    const [pessoa, setPessoa] = useState(null);
    const [centrosdecusto, setCentrosDeCusto] = useState(null);
    const [centrodecusto, setCentroDeCusto] = useState(null);
    const [hospedes, setHospedes] = useState([]);
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [reserva, setReserva] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [paxsForRooms, setPaxsForRooms] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [personsNumber, setPersonsNumber] = useState(0);
    const [listCotacao, setListCotacao] = useState([])
    const [emailsCotar, setEmailsCotar] = useState([]);

    const [valuesHotel, setValuesHotel] = useState({
        total: 0,
        TaxandFee: 0,
        TotalPerPerson: 0
    });
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const handleChangeAuto = (campo, valor) => {
    }

    const handleSearchHotel = async () => {
        setStep(1)
        setLoading(true)
        setHotelrules(null)
        setListCotacao([])
        try {
            if (locationid == null) {
                setMensagemSnack('Escolha um destino');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
            }

            var paxRooms = [];

            roomsForHotel.forEach((item) => {
                var pax = [];

                for (let i = 0; i < item.adultos; i++) {
                    setPersonsNumber(personsNumber + 1)
                    pax.push({ age: 23 });
                }

                item.crianças.forEach(element => {
                    pax.push({ age: element.idade });
                });

                paxRooms.push({ pax: pax });
            });

            let data = {
                availableRatesOnly: true,
                domain: "AGENCIA",
                agencyID: 0,
                locationID: locationid.value,
                paxGroup: paxRooms,
                checkIn: checkin.toISOString().split('T')[0],
                checkOut: checkout.toISOString().split('T')[0],
                // Atribui "true" se NÃO contiver "Brasil" (ou seja, é internacional)
                is_internacional: !(
                    (locationid.subtitle?.toLowerCase() ?? "").includes("brasil")
                ),
            };

            const response = await hotelRepositorio.postSearchHotels(data);
            setListHotel(response);
        } catch (error) {
            setMensagemSnack('Erro ao realizar a pesquisa de hotéis!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setLoading(false)
        }
    };

    const GetLocations = async () => {
        try {
            setLoading(true)
            const response = await hotelRepositorio.getLocations();
            const citiesWithHotels = response.flatMap((item) =>
                item.states.flatMap((state) =>
                    state.locations
                        // .filter((location) => location.namePt)
                        .map((location) => (
                            {
                                label: (item.namePt === "" ? location.namePt.substring(location.namePt.lastIndexOf(" - ") + 3) : location.namePt),
                                subtitle: (item.namePt === "" ? `${location.namePt}` : `${item.namePt} • ${state.namePt} • ${location.namePt}`),
                                value: location.id
                            }))
                )
            );
            setLocations(citiesWithHotels);
        } catch {
            setMensagemSnack('Erro ao realizar a pesquisa de locais!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setLoading(false)
        }
    }

    const handleChangeRoom = () => {
        handleClickPlan();
        setPaxsForRooms([])
        setStep(3)
    };

    const GetLocationUser = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocationUser({ latitude, longitude });
                },
                (error) => {
                    console.error(`Erro ao obter localização: ${error.message}`);
                }
            );
        } else {
            console.error('Geolocalização não é suportada pelo seu navegador.');
        }
    };

    const handleClickDisponiblity = async (id, rates, lowerPrices, image) => {
        setHotelprices(null)
        setHotelPricesRooms(null)
        setHotelrules(null)
        setHospedes([])
        setStep(2)
        try {
            let listatokes = [];
            rates.forEach((quartos) => {
                quartos.rates.forEach((item) => {
                    const productToken = item.productToken;
                    listatokes.push(productToken);
                });
            });

            const response = await hotelRepositorio.getHotelDetail(id);
            response.lowerPrices = lowerPrices;

            if (response == null) {
                setMensagemSnack('Erro ao obter disponibilidade do hotel!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
            }

            if (response.images.length === 0) {
                response.images = [{ thumb: image, id: '0', name: 'MAIN', description: 'MAIN' }]
            }

            setHotel(response);

            const response3 = await hotelRepositorio.postHotelPrice({
                productTokenList: listatokes,
                HotelId: response.id,
                Token: listHotel.correlationId,
                CheckIn: listHotel.checkIn,
                CheckOut: listHotel.checkOut
            })
            setHotelPricesRooms(response3);
            setHotelprices(rates)
        } catch {
            setMensagemSnack('Erro ao obter disponibilidade do hotel!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
        }
    }

    const handleClickPlan = async () => {
        try {
            let listatokes = [];
            roomSelections.forEach((quartos) => {
                const productToken = quartos.plano.productToken;
                listatokes.push(productToken);
            });

            const response2 = await hotelRepositorio.postHotelRules({
                productTokenList: listatokes,
                HotelId: hotel.id,
                Token: listHotel.correlationId,
                CheckIn: listHotel.checkIn,
                CheckOut: listHotel.checkOut,
                is_internacional: !(
                    (locationid.subtitle?.toLowerCase() ?? "").includes("brasil")
                ),
                tipo_moeda: listHotel.hotels[0].rooms[0].rates[0].originalCurrency
            });

            if (response2 != null) {
                roomSelections.forEach((quartos, index) => {
                    response2.bookingRulesResponse.forEach((cancel) => {
                        if (quartos.plano.productToken === cancel.productToken) {
                            quartos.cancelpolicy = cancel;
                            const updatedRoomSelections = [...roomSelections];
                            setRoomSelections(updatedRoomSelections);
                        }
                    });
                });
            }

            setHotelrules(response2);
        } catch {
            setMensagemSnack('Erro ao selecionar plano!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
        }
    }

    const getCentroDeCusto = async () => {
        setLoading(true)
        try {
            await centroCustoRepositorio.getSearchField().then(response => setCentrosDeCusto(response));
        } catch {
            setMensagemSnack('Erro ao obter centro de custo!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setLoading(false)
        }
    }

    const getPessoas = async () => {
        setLoading(true)
        try {
            let responseViajantes = await pessoaRepositorio.getSearchField();
            setPessoas(responseViajantes);
        } catch {
            setMensagemSnack('Erro ao obter lista de pessoas!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setLoading(false)
        }
    }

    const getSolicitacao = async () => {
        try {
            const responseSolicitante = await usuarioRepositorio.getSearchField();
            setSolicitantes(responseSolicitante);
        } catch {

        } finally {

        }
    }

    const addViajante = async (dto) => {
        const viajanteComQuarto = { ...dto, quarto: { label: "Nenhum quarto", value: 0 } };
        setHospedes(oldValues => [...oldValues, viajanteComQuarto]);
        setPessoa(null)
        setCentroDeCusto(null)
    }

    const handleClickAdicionarHospede = async () => {
        let dto = {
            pessoa: pessoa,
            centroCusto: politicaViagem.utilizarcentrocusto ? centrodecusto : centrosdecusto[0]
        }

        if (!pessoa) {
            setMensagemSnack('Viajante não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        if (!dto.centroCusto) {
            setMensagemSnack('Centro de Custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        if (!validatorCPF(pessoa.pessoa.cpf) && pessoa.pessoa.nacionalidade === 1) {
            setMensagemSnack('CPF do(a) viajante está inválido.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }

        if (!pessoa.pessoa.datanascimento) {
            setMensagemSnack('A data de nascimento do(a) viajante está vazia.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }

        if (pessoa.pessoa.telefone === "(__)_____-____" || pessoa.pessoa.telefone === null || pessoa.pessoa.telefone === 'undefined' || pessoa.pessoa.telefone.trim() === "") {
            setMensagemSnack('O telefone do(a) viajante está vazio.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }

        try {
            let dtoSend = { idpassageiro: pessoa.pessoa.idpessoa }
            let response = await pessoaRepositorio.postVerificarCpf(dtoSend)
            if (response.status === 207) {
                setMensagemSnack(response.data.message);
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }

            if (response.status === 202) {
                setMensagemSnack(response.data.message);
                setTipoSnack('info');
                setSnackbarSucess({ visible: true });
            }
        } catch (e) {
            setMensagemSnack('Falha ao verificar dados do(a) viajante.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return
        }

        addViajante(dto)
    }

    const handleClickRemoverHospede = (index) => {
        setHospedes(hospedes.filter((_, i) => i !== index));
    }

    const handleClickReservar = async () => {
        setLoading(true)
        try {
            let rooms = []
            let error = false

            if (paxsForRooms.length === 0) {
                setMensagemSnack('Adicione ' + stepperAdultos + ' Hóspede(s)');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return;
            }

            if (solicitante === null) {
                setMensagemSnack('Solicitante não atribuido');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return;
            }

            paxsForRooms.forEach(paxsForRoom => {
                let pax = [];

                if (paxsForRoom.hospedes.length === 0) {
                    setMensagemSnack('Há quartos vazios!');
                    setTipoSnack('error');
                    setSnackbarSucess({ visible: true });
                    error = true
                    return;
                }

                if (paxsForRoom.hospedes.length !== (stepperAdultos + stepperCriancas)) {
                    setMensagemSnack('A reserva são para ' + (stepperAdultos + stepperCriancas) + ' Hóspede(s)');
                    setTipoSnack('error');
                    setSnackbarSucess({ visible: true });
                    error = true
                    return;
                }

                paxsForRoom.hospedes.forEach(item => {
                    if (item === null) {
                        setMensagemSnack('Hóspede não atribuido aos quartos selecionados');
                        setTipoSnack('error');
                        setSnackbarSucess({ visible: true });
                        error = true
                        return;
                    }
                    let person = {
                        idperson: item.pessoa.value,
                        idcentrocusto: item.centroCusto.value
                    }
                    pax.push(person);
                })
                let room = {
                    productToken: paxsForRoom.quarto.plano.productToken,
                    pax,
                    Hotel_Reservar_Quarto: {
                        Valor_diaria: paxsForRoom.quarto.plano.priceperday, //pendente
                        Valor_diaria_Moeda: paxsForRoom.quarto.plano.priceperday, //aqui
                        Data_entrada: checkin, //hotel
                        Data_saida: checkout, // hotel
                        Descricao_tipo_quarto: paxsForRoom.quarto.name,
                        Descricao_tipo_acomodacao: paxsForRoom.quarto.type,
                        Codigo_quarto: paxsForRoom.quarto.id, //conferir
                        Token_produto: paxsForRoom.quarto.plano.productToken, //conferir
                        Servicos_quartos: paxsForRoom.quarto.description,
                        Servicos_hotel: hotel.facilities.map(facility => facility.name).join('</br>'),
                        Regime: paxsForRoom.quarto.plano.description,
                        Tem_politica: paxsForRoom.quarto.plano.Tem_politica,
                        Valor_politica: paxsForRoom.quarto.plano.Valor_politica,
                        Total_Moeda: paxsForRoom.quarto.plano.total_moeda,
                        Tipo_Moeda: paxsForRoom.quarto.plano.tipo_moeda,
                        total: paxsForRoom.quarto.plano.total,
                        Cotacao_Moeda: paxsForRoom.quarto.plano.total / paxsForRoom.quarto.plano.total_moeda,
                        MaisINFO: ""
                    },
                    Hotel_Reserva_Quarto_Regras: hotelrules
                }
                rooms.push(room)
            })

            if (hotelrules === null) {
                setMensagemSnack('Tentando encontrar as regra do quarto!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
            }

            if (error) {
                return
            }
            const hotelDetails = {
                Hotel_Reservar_DataBase: {
                    Telefone: hotel.contact.phones[0],
                    Email: hotel.contact.email,
                    Url_image: hotel.images[0].thumb,
                    Nome: hotel.name,
                    Nome_rede: hotel.chain,
                    Hotel_code: hotel.id,
                    Estrelas: hotel.award.rating,
                    Total: valuesHotel.total, //adicionar totalPriceWithTaxFee.
                    Correlation_id: hotelpricesRooms.priceResponseList[0].correlationId
                },
                Hotel_Reservar_Localizacao: {
                    Endereco: hotel.location.address,
                    Codigo_postal: hotel.location.postalCode,
                    Localizacao_id: hotel.location.id,
                    Cidade: hotel.location.city,
                    Estado: hotel.location.stateName,
                    Pais: hotel.location.countryName,
                    Latitude: hotel.location.latitude,
                    Longitude: hotel.location.longitude
                },
                Hotel_Reservar_Detalhes: {
                    E_exclusivo: hotel.hasExclusive,
                    Tem_piscina: hotel.hasPool,
                    Tem_estacionamento: hotel.hasParking,
                    Tem_lavanderia: hotel.hasLaundry,
                    Tem_restaurante: hotel.hasRestaurant,
                    Descricao: hotel.description
                },
                Rooms: rooms,
                idsolicitante: solicitante.value
            };
            // return;

            const response = await hotelRepositorio.postHotelReserve(hotelDetails);
            if (response != null) {
                setMensagemSnack('Reservado!');
                setTipoSnack('success');
                setSnackbarSucess({ visible: true });
                setTimeout(() => {
                    props.history.push("/listaReservas");
                }, 1500);
            }
            setStep(1)
        } catch {
            setMensagemSnack('Ocorreu um erro!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEnviarCotar = async () => {
        try {
            setLoading(true)
            let hoteis = [];

            listCotacao.forEach(hotel => {
                // Verifica se já existe um hotel na lista com o mesmo id
                let hotelExistente = hoteis.find(h => h.Nome === hotel.hotelname);

                if (hotelExistente) {
                    // Verifica se já existe um quarto neste hotel com a mesma descrição
                    let quartoExistente = hotelExistente.Quartos.find(quarto => quarto.id === hotel.id && quarto.nome === hotel.description);

                    if (quartoExistente) {
                        // Se o quarto já existe, apenas adiciona ou atualiza a tarifa
                        let tarifaExistente = quartoExistente.tarifas.find(tarifa => tarifa.idhotel === hotel.id && tarifa.description === hotel.description);

                        if (tarifaExistente) {
                            // Atualiza a tarifa existente
                            tarifaExistente.valordiaria = hotel.priceperday;
                            tarifaExistente.valortotal = hotel.price;
                            tarifaExistente.detalhes = hotel.hoteldetalhes;
                            tarifaExistente.oferta = hotel.superFare;
                        } else {
                            // Adiciona uma nova tarifa
                            quartoExistente.tarifas.push({
                                idhotel: hotel.id,
                                description: hotel.description,
                                valordiaria: hotel.priceperday,
                                valortotal: hotel.price,
                                detalhes: hotel.hoteldetalhes,
                                oferta: hotel.superFare
                            });
                        }
                    } else {
                        // Se o quarto não existe, cria um novo com a primeira tarifa
                        hotelExistente.Quartos.push({
                            id: hotel.id,
                            nome: hotel.description,
                            tarifas: [{
                                idhotel: hotel.id,
                                description: hotel.description,
                                valordiaria: hotel.priceperday,
                                valortotal: hotel.price,
                                detalhes: hotel.hoteldetalhes,
                                oferta: hotel.superFare
                            }]
                        });
                    }
                } else {
                    // Se o hotel não existe, cria um novo com o primeiro quarto e tarifa
                    hoteis.push({
                        id: hotel.id,
                        Nome: hotel.hotelname,
                        Estrelas: hotel.hotelStars,
                        Descricao: hotel.hoteldescription,
                        Endereco: hotel.hotelendereco,
                        Link_foto: hotel.hotelthumb,
                        Comodidades: hotel.hotelcomodidades,
                        Quartos: [{
                            id: hotel.id,
                            nome: hotel.description,
                            tarifas: [{
                                idhotel: hotel.id,
                                description: hotel.description,
                                valordiaria: hotel.priceperday,
                                valortotal: hotel.price,
                                detalhes: hotel.hoteldetalhes,
                                oferta: hotel.superFare
                            }]
                        }]
                    });
                }
            });

            if (hoteis.length === 0) {
                setMensagemSnack("Adicione uma tarifa para envio");
                setTipoSnack('info');
                setSnackbarSucess({ visible: true });
                return
            }

            if (emailsCotar.length === 0) {
                setMensagemSnack("Adicione emails para envio");
                setTipoSnack('info');
                setSnackbarSucess({ visible: true });
                return
            }

            const model = {
                Emails: emailsCotar,
                Hoteis: hoteis,
                Pesquisa: {
                    CheckIn: checkin,
                    CheckOut: checkout,
                    Quantidade_Adultos: stepperAdultos,
                    Quantidades_Criancas: 0,
                    Pais: "",
                    Estado: "",
                    Cidade: locationid.subtitle,
                    DataPesquisada: new Date()
                }
            }
            await hotelRepositorio.postEnviarHoteisCotar(model)
            setMensagemSnack("Cotação enviada com sucesso");
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
            setListCotacao([])
        } catch {
            setMensagemSnack("Ocorreu um erro ao enviar cotação");
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setEmailsCotar([])
            setLoading(false)
        }
    }

    const handleClickRemoverDaListCotar = (id) => {
        setListCotacao(prevList => prevList.filter(rate => rate.id !== id));
    }

    useEffect(() => {
        setValuesHotel({
            total: 0,
            totalpordia: 0,
            totaltax: 0
        });

        let totalSum = 0;
        let totalPerDaySum = 0;
        let totaltaxSum = 0;

        // Itera sobre as seleções de quarto
        roomSelections.forEach((item) => {
            if (item.plano && item.plano.price) {
                totalSum += item.plano.price; // Soma os preços
            }
            if (item.plano && item.plano.priceperday) {
                totalPerDaySum += item.plano.priceperday; // Soma os preços por dia
            }
            if (item.plano && item.plano.pricetax) {
                totaltaxSum = item.plano.pricetax;
            }
        });

        setValuesHotel(prevValues => ({
            ...prevValues,
            total: totalSum,
            totalpordia: totalPerDaySum,
            totaltax: totaltaxSum
        }));
    }, [roomSelections])

    useEffect(() => {
        if (step === 1) {
            setHotel(null)
        }
    }, [step])

    useEffect(() => {
        setStep(1)
        setListHotel(null)
    }, [roomsForHotel])


    useEffect(() => {
        GetLocationUser()
        GetLocations()
        getPessoas()
        getCentroDeCusto()
        getSolicitacao()
    }, [])

    return (
        <>
            <CustomizedSnackbars {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />

            <ULoaderVoo isLoading={loading} />
            {responsive ? (
                <>
                    <HotelMobile
                        {...{
                            stepperAdultos,
                            setStepperAdultos,
                            stepperCriancas,
                            setStepperCriancas,
                            stepperRooms,
                            setStepperRooms,
                            locations,
                            setLocations,
                            hoteis,
                            setHoteis,
                            handleChangeAuto,
                            handleSearchHotel,
                            loading,
                            setLoading,
                            paxGroup,
                            setPaxGroup,
                            checkin,
                            setCheckin,
                            checkout,
                            setCheckout,
                            locationid,
                            setLocationid,
                            listHotel,
                            setListHotel,
                            orderprice,
                            setOrderprice,
                            orderprices,
                            setOrderprices,
                            locationUser,
                            setLocationUser,
                            GetLocations,
                            handleClickDisponiblity,
                            hotel,
                            setHotel,
                            hotelrules,
                            setHotelrules,
                            hotelprices,
                            step,
                            setStep,
                            getPessoas,
                            pessoa,
                            pessoas,
                            setPessoa,
                            setPessoas,
                            centrosdecusto,
                            setCentrosDeCusto,
                            centrodecusto,
                            setCentroDeCusto,
                            solicitantes,
                            solicitante,
                            setSolicitante,
                            hospedes,
                            setHospedes,
                            handleClickAdicionarHospede,
                            handleClickRemoverHospede,
                            reserva,
                            setReserva,
                            selectedRooms,
                            setSelectedRooms,
                            handleChangeRoom,
                            rooms,
                            setRooms,
                            handleClickReservar,
                            valuesHotel,
                            roomsForHotel,
                            setRoomsForHotel,
                            personsNumber,
                            setPersonsNumber,
                            roomSelections,
                            setRoomSelections,
                            paxsForRooms,
                            setPaxsForRooms,
                            handleClickPlan,
                            listCotacao,
                            setListCotacao, handleClickEnviarCotar, handleClickRemoverDaListCotar, emailsCotar, setEmailsCotar
                        }}
                    />
                </>
            ) : (
                <>
                    <HotelDesktop
                        {...{
                            stepperAdultos,
                            setStepperAdultos,
                            stepperCriancas,
                            setStepperCriancas,
                            stepperRooms,
                            setStepperRooms,
                            locations,
                            setLocations,
                            hoteis,
                            setHoteis,
                            handleChangeAuto,
                            handleSearchHotel,
                            loading,
                            setLoading,
                            paxGroup,
                            setPaxGroup,
                            checkin,
                            setCheckin,
                            checkout,
                            setCheckout,
                            locationid,
                            setLocationid,
                            listHotel,
                            setListHotel,
                            orderprice,
                            setOrderprice,
                            orderprices,
                            setOrderprices,
                            locationUser,
                            setLocationUser,
                            GetLocations,
                            handleClickDisponiblity,
                            hotel,
                            setHotel,
                            hotelrules,
                            setHotelrules,
                            hotelprices,
                            step,
                            setStep,
                            getPessoas,
                            pessoa,
                            pessoas,
                            setPessoa,
                            setPessoas,
                            centrosdecusto,
                            setCentrosDeCusto,
                            centrodecusto,
                            setCentroDeCusto,
                            solicitantes,
                            solicitante,
                            setSolicitante,
                            hospedes,
                            setHospedes,
                            handleClickAdicionarHospede,
                            handleClickRemoverHospede,
                            reserva,
                            setReserva,
                            selectedRooms,
                            setSelectedRooms,
                            handleChangeRoom,
                            rooms,
                            setRooms,
                            handleClickReservar,
                            valuesHotel,
                            roomsForHotel,
                            setRoomsForHotel,
                            personsNumber,
                            setPersonsNumber,
                            typeRooms,
                            setTypeRooms,
                            roomSelections,
                            setRoomSelections,
                            paxsForRooms,
                            setPaxsForRooms,
                            handleClickPlan,
                            listCotacao,
                            setListCotacao, handleClickEnviarCotar, handleClickRemoverDaListCotar, emailsCotar, setEmailsCotar
                        }}
                    />
                </>
            )}
        </>
    )
}


export const HotelPageDash = (props) => {
    return (
        <div>
            <Dashboard {...props} component={HotelPage} />
        </div>
    );
};
export default HotelPageDash;
